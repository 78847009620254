export const usePlaceList = () => {
  const client = useDatabaseClient();
  const editionModal = useModal("placeEditionModal");
  const { showError } = useAlert();

  const query = useAsyncData(async () => {
    const { data } = await client
      .from("places")
      .select()
      .order("name")
      .throwOnError();

    return data;
  });

  const create = async () =>
    editionModal.open<DatabaseInsertRequest<"places">>(
      {},
      {
        onConfirm: async (response) => {
          await client.from("places").insert(response);

          return query.refresh();
        },
      },
    );

  const deletePlace = async (place: DatabaseTable<"places">) => {
    const { error } = await client.from("places").delete().eq("id", place.id);

    if (error) {
      return showError(error.message);
    }

    return query.refresh();
  };

  const update = async (place: DatabaseTable<"places">) =>
    editionModal.open<DatabaseInsertRequest<"places">>(
      { place },
      {
        onConfirm: async (response) => {
          await client.from("places").update(response).eq("id", place.id);

          return query.refresh();
        },
      },
    );

  return {
    ...query,
    create,
    update,
    deletePlace,
  };
};
